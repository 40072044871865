import axios from '@/core/axios';

import { Attempt, DeviceFailureTask, DeviceFailures } from './device-failures.types';

export class DeviceFailuresService {
  get(serial_no: string, full: boolean): Promise<DeviceFailures[]> {
    if (!serial_no) throw new Error('Missing serial_no in get');
    return axios.post<DeviceFailures[]>(`/device_failures/${serial_no}`, { full }).then(resp => resp?.data);
  }

  create(deviceFailure: DeviceFailures): Promise<DeviceFailures> {
    if (!deviceFailure) throw new Error('Missing deviceFailure in create');
    return axios
      .post<DeviceFailures>('device_failures', { deviceFailure })
      .then(resp => {
        return resp?.data;
      })
      .catch(error => {
        console.error('Error during the creation of deviceFailure: ', error);
        throw error;
      });
  }

  update(deviceFailureId: number, deviceFailure: Partial<DeviceFailures>): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in update');
    return axios.put<DeviceFailures>(`device_failures/${deviceFailureId}`, { deviceFailure }).then(resp => resp?.data);
  }

  delete(serial_no: string, list: number[]): Promise<DeviceFailures[]> {
    if (!serial_no) throw new Error('Missing serial_no in delete');
    return axios.put<DeviceFailures[]>(`device_failures/${serial_no}/delete`, { list }).then(resp => resp?.data);
  }

  validateD(deviceFailureId: number, timer: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in validate');
    return axios
      .post<DeviceFailures>(`device_failures/${deviceFailureId}/validateD`, { timer })
      .then(resp => resp?.data);
  }

  validateR(deviceFailureId: number, timer: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in validateR');
    return axios
      .post<DeviceFailures>(`device_failures/${deviceFailureId}/validateR`, { timer })
      .then(resp => resp?.data);
  }

  validateFromPreparation(deviceFailureId: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in validateFromPreparation');
    return axios.get<DeviceFailures>(`device_failures/${deviceFailureId}/validateFromPrepare`).then(resp => resp?.data);
  }

  undoFromPreparation(deviceFailureId: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in undoFromPreparation');
    return axios.get<DeviceFailures>(`device_failures/${deviceFailureId}/undoFromPrepare`).then(resp => resp?.data);
  }

  closeDeviceFailure(deviceFailureId: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in closeDeviceFailure');
    return axios.get<DeviceFailures>(`device_failures/${deviceFailureId}/closeDeviceFailure`).then(resp => resp?.data);
  }

  validateTask(deviceFailureId: number, data: { task: DeviceFailures; timer: number }): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in validateTask');
    return axios
      .post<DeviceFailures>(`device_failures/${deviceFailureId}/validate_task`, data)
      .then(resp => resp?.data);
  }

  undoTask(deviceFailureId: number, task: DeviceFailureTask): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in undoTask');
    return axios
      .post<DeviceFailures>(`device_failures/${deviceFailureId}/undo_task`, { task })
      .then(resp => resp?.data);
  }

  getNextTask(deviceFailureId: number, response: boolean): Promise<DeviceFailureTask> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in getNextTask');
    return axios
      .get<DeviceFailureTask>(`/device_failures/${deviceFailureId}/next_task/${response}`)
      .then(resp => resp?.data);
  }

  editAttempt(deviceFailureId: number, attempt: Attempt): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in editAttempt');

    return axios
      .post<DeviceFailures>(`/device_failures/${deviceFailureId}/edit_attempt`, { attempt })
      .then(resp => resp?.data);
  }

  failedRepair(deviceFailureId: number): Promise<DeviceFailures> {
    if (!deviceFailureId) throw new Error('Missing deviceFailureId in failedRepair');

    return axios.get<DeviceFailures>(`/device_failures/${deviceFailureId}/failed_repair`).then(resp => resp?.data);
  }
}

export const deviceFailuresService = new DeviceFailuresService();
